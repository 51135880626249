<template>
    <v-card class="ma-3">
        <v-card-title>
            <span v-if="displayOptions.showTitle">Material Configs</span>
            <v-spacer v-if="displayOptions.showTitle" />
            <v-text-field
                v-model="searchString"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            />
            <v-spacer />
            <v-btn-toggle
                v-if="!onlyOneSeriesAvail"
                v-model="seriesFiltering"
                multiple
                mandatory
                rounded
                color="primary"
                class="pr-4"
            >
                <v-btn
                    v-if="show2x"
                    small
                    value="2"
                >
                    2-series
                </v-btn>
                <v-btn
                    v-if="show3x"
                    small
                    value="3"
                >
                    3-series
                </v-btn>
            </v-btn-toggle>
            <MaterialConfigForm
                :existing-configs2x="configs2x"
                :existing-configs3x="configs3x"
                @update-material-configs="updateData()"
            />
        </v-card-title>
        <v-card-text class="pb-0">
            <v-data-table
                v-model="selectedMatConfig"
                :headers="headers"
                :items="displayedConfigs"
                item-key="displayId"
                :loading="loadingBarOn"
                :sort-by="['retired', 'created_at']"
                :sort-desc="[false, true]"
                :search="searchString"
                :items-per-page="displayOptions.numPerPage"
                :show-select="displayOptions.showSelect"
                :single-select="displayOptions.showSelect"
                multi-sort
                class="my-3"
            >
                <template v-slot:[`item.retired`]="{ item }">
                    <v-chip
                        :color="item.retired? 'red' : 'green'"
                    >
                        {{ item.retired ? 'retired' : 'active' }}
                    </v-chip>
                </template>
                <template v-slot:[`item.created_at`]="{ item }">
                    {{ new Date(item.created_at).toLocaleString() }}
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    <v-btn
                        icon
                        @click="selectConfig(item)"
                    >
                        <v-icon color="primary">
                            mdi-information-outline
                        </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </v-card-text>
        <MaterialConfigModal @update-material-configs="updateData()" />
    </v-card>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import MaterialConfigForm from './MaterialConfigForm.vue';
import MaterialConfigModal from './MaterialConfigModal.vue'

export default {
    components: {
        MaterialConfigForm,
        MaterialConfigModal,
    },
    props: {
        displayOptions: {
            type: Object,
            default: function () {
                return { 
                    numPerPage: -1,
                    showTitle: true,
                    showSelect: false,
                    showRetired: true,
                }
            }
        }
    },
    data: function() {
        return {
            headers: [
                {
                    text: 'ID',
                    align: 'left',
                    value: 'displayId'
                },
                { text: 'Created At', value: 'created_at' },
                { text: 'Series', value: 'config_series' },
                { text: 'Config Name', value: 'name' },
                { text: 'Created By', value: 'user' },
                { text: 'Status', value: 'retired' },
                { text: 'View', value: 'action', sortable: false }
            ],
            configs2x:[],
            configs3x:[],
            selected: {},
            loadingBarOn: true,
            matTypes: [], 
            searchString: '',
            seriesFiltering:[],
            displayedConfigs: [],
            selectedMatConfig: undefined,
        }
    },
    computed: {
        ...mapState([
            'baseURL',
            'dialogs',
            'iam',
        ]),
        ...mapGetters([
            'show2x',
            'show3x',
            'onlyOneSeriesAvail',
        ]),
    },
    watch: {
        seriesFiltering: function() {
            this.filterConfigsForDisplay();
        },
        selectedMatConfig: function(val) {
            this.$emit('selected-mat-config', val[0]);
        }
    },
    mounted() {
        this.updateData();

        this.seriesFiltering = JSON.parse(JSON.stringify(this.iam.availableSeries));
    },
    methods: {
        selectConfig(details) {
            this.dialogs.materialConfig.open = true;
            this.dialogs.materialConfig.details = details;
        },
        async updateData() {
            if (this.show2x) {
                this.configs2x = await this.getMaterialConfigs(2);
            }
            if (this.show3x) {
                this.configs3x = await this.getMaterialConfigs(3);
            }
            this.filterConfigsForDisplay();
        },
        async getMaterialConfigs(series) {
            let path;
            let vm = this;
            if (series == 2) {
                path = '/database/materialConfigs'
            }
            else if(series == 3) {
                path = '/database/matConfigs3x'
            }

            let t = await vm.$auth.getTokenSilently();
            vm.loadingBarOn = true;
            
            try {
                let resp = await vm.$http.get(vm.baseURL+path, {
                    headers: { Authorization: `Bearer ${t}` }
                })

                console.log("Response from ", path, " ", resp.data.body);
                let configs = resp.data.body;

                let configsInOrg = configs
                    .filter(cfg => cfg.org_id == this.iam.org_id)
                    .map(cfg => ({
                        ...cfg,
                        user: `${cfg.first_name} ${cfg.last_name}`,
                        config_series: series,
                        // do not change without seeing emit 'selected-mat-config'
                        // above and file CreateJobModal
                        displayId: `${series}-${String(cfg.id).padStart(3, '0')}`
                    }));

                vm.loadingBarOn = false;
                return configsInOrg;
            }
            catch(e) {
                console.error(e);
            }
        },
        filterConfigsForDisplay() {
            this.displayedConfigs = [];
            if (this.seriesFiltering.includes('2')) {
                this.displayedConfigs = this.displayedConfigs.concat(this.configs2x);
            }
            if (this.seriesFiltering.includes('3')) {
                this.displayedConfigs = this.displayedConfigs.concat(this.configs3x)
            }
            if (!this.displayOptions.showRetired) {
                this.displayedConfigs = this.displayedConfigs.filter(config => !config.retired)
            }
        },
    }
}
</script>
