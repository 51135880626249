function exportToCSV(itemList, filename) {
    if (itemList.length == 0) { return; }
    let array = itemList;
    let csvContent = '';
    let headers = Object.keys(array[0]);
    headers.forEach((header) => {
        csvContent += ',' + header;
    })
    csvContent = csvContent.substr(1);
    csvContent += '\r\n';

    array.forEach((result) => {
        let line = '';
        for (var index in result) {
            if (line != '') line += ','

            // scrub the commas (delimiter) from string
            line += "\"" + String(result[index]).replace(/,/g, '-') + "\""
        }
        csvContent += line + '\r\n';
    })

    let now = new Date();
    let dateString = 
        (now.getMonth()+1).toString().padStart(2, '0') +
        now.getDate().toString().padStart(2, '0') + "t" +
        now.getHours().toString().padStart(2, '0') +
        now.getMinutes().toString().padStart(2, '0');

    csvContent = 'data:text/csv;charset=utf-8,' + csvContent;
    let data = encodeURI(csvContent);
    let link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename + "_" + dateString + '.csv');
    link.click();
}

export {exportToCSV}