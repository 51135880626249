<template>
    <v-container>
        <v-row class="text-center">
            <v-img
                :src="require('../../images/icons/light_logo.png')"
                class="my-3"
                contain
                height="150"
            />
        </v-row>
        <v-row>
            <v-col
                cols="12"
                class="center-x"
            >
                <v-card
                    flat
                    width="40%"
                >
                    <v-card-title>
                        <v-spacer />
                        Fortify's Flux Portal
                        <v-spacer />
                    </v-card-title>
                    <v-card-text class="center-x">
                        <v-btn 
                            outlined
                            color="primary"
                            @click="$auth.loginWithRedirect()"
                        >
                            Log In
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'SignIn',

    data: () => ({
    }),
}
</script>
