<template>
    <v-card
        outlined
        height="100%"
        class="d-flex flex-column"
        color="gray3"
    >
        <v-card-title class="pb-1">
            <span class="subtitle-1 font-weight-medium">
                {{ jobDetails.name }} <span class="overline grey--text pl-2">J-ID {{ jobDetails.id }}</span>
            </span>
            <v-spacer />
            <span class="overline">
                {{ jobDetails.config_series }} Series
            </span>
        </v-card-title>
        <v-card-subtitle class="pb-0 grey--text">
            <span class="overline">
                Created by {{ jobDetails.first_name }} {{ jobDetails.last_name }}
                <span class="white--text subtitle-1 mx-2">|</span>
                {{ convertDatabaseDate(jobDetails.created_at) }}
            </span>
        </v-card-subtitle>
        <!-- <v-divider style="width: 60%; margin: auto"></v-divider> -->
        <v-card-text class="white--text pt-1 pb-0">
            <v-row dense>
                <v-col
                    cols="3"
                    class="d-flex center-x pa-0"
                >
                    <v-img
                        v-if="jobDetails.image_url"
                        :src="jobDetails.image_url"
                        contain
                        max-width="180"
                    />
                </v-col>

                <v-col cols="4">
                    <v-card
                        outlined
                        color="gray3"
                    >
                        <v-card-text class="py-0 white--text">
                            <v-row>
                                <v-col
                                    cols="12"
                                    class="pa-2"
                                >
                                    <span class="subtitle-2 grey--text">Print File</span>
                                    <br>
                                    {{ jobDetails.printFile_name }}
                                </v-col>
                                <v-col
                                    cols="6"
                                    class="pa-2"
                                >
                                    <span class="grey--text">Num Layers</span>
                                    <br>
                                    {{ jobDetails.num_layers }}
                                    <span class="font-weight-light">layers</span>
                                </v-col>
                                <v-col
                                    cols="6"
                                    class="pa-2"
                                >
                                    <span class="grey--text">Layer Height</span>
                                    <br>
                                    {{ jobDetails.layer_height }}
                                    <span class="font-weight-light">mm</span>
                                </v-col>
                                <v-col
                                    cols="6"
                                    class="pa-2"
                                >
                                    <span class="grey--text">Total Volume</span>
                                    <br>
                                    {{
                                        Math.round((jobDetails.parts_volume||0 +
                                            jobDetails.supports_volume||0 + jobDetails.raft_volume||0)
                                            /1000)
                                    }}
                                    <span class="font-weight-light">mL</span>
                                </v-col>
                                <v-col
                                    cols="6"
                                    class="pa-2"
                                >
                                    <span class="grey--text">Magnet Mode</span>
                                    <br>
                                    {{ jobDetails.magnet_mode }}
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col
                    cols="1"
                    class="center-x"
                >
                    <v-divider
                        vertical
                        inset
                    />
                </v-col>

                <v-col cols="4">
                    <v-card
                        outlined
                        color="gray3"
                    >
                        <v-card-text class="py-0 white--text">
                            <v-row>
                                <v-col
                                    cols="12"
                                    class="pa-2"
                                >
                                    <span class="subtitle-2 grey--text">Material Config</span>
                                    <br>
                                    {{ jobDetails.mat_config_name_2x || jobDetails.mat_config_name_3x }}
                                </v-col>
                                <v-col
                                    cols="6"
                                    class="pa-2"
                                >
                                    <span class="grey--text">Layer Height</span>
                                    <br>
                                    {{ jobDetails.layer_height_2x || jobDetails.layer_height_3x || 'N/A' }} um
                                </v-col>
                                <v-col
                                    cols="6"
                                    class="pa-2"
                                >
                                    <span class="grey--text">Material Type</span>
                                    <br>
                                    {{ jobDetails.mat_type }}
                                </v-col>
                                <v-col
                                    cols="6"
                                    class="pa-2"
                                >
                                    <span class="grey--text">Config Status</span>
                                    <br>
                                    {{ matConfigRetired ? 'Retired' : 'Active' }}
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="mt-n11">
            <v-spacer />
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        :disabled="jobDetails.retired"
                        v-bind="attrs"
                        @click="openChangeJobStatusModal()"
                        v-on="on"
                    >
                        <v-icon>mdi-delete-variant</v-icon>
                    </v-btn>
                </template>
                <span>Retire Job</span>
            </v-tooltip>
        </v-card-actions>

        <v-overlay
            absolute
            :value="jobDetails.retired"
            opacity="0.6"
        >
            <v-btn
                color="primaryDark"
                @click="openChangeJobStatusModal()"
            >
                Activate Job
            </v-btn>
        </v-overlay>
    </v-card>
</template>

<script>
import {mapState} from 'vuex';

export default {
    props:[
        'jobDetails',
    ],
    data: function(){
        return {
            isoImageURL: undefined,
        }
    },
    computed: {
        ...mapState([
            'baseURL',
            'dialogs',
        ]),
        matConfigRetired: function() {
            return this.jobDetails.retired_2x | this.jobDetails.retired_3x;
        }
    },
    mounted() {
        // this.getIsoViewURL();
    },
    methods: {
        convertDatabaseDate(inputDate) {
            let date = new Date(inputDate);
            return date.toLocaleString();
        },
        openChangeJobStatusModal() {
            this.dialogs.retireJobConfirmation.details = {
                jobID: this.jobDetails.id,
                jobName: this.jobDetails.name,
                currentStatusRetired: this.jobDetails.retired,
            };
            this.dialogs.retireJobConfirmation.open = true;
        },
    }

}
</script>

<style scoped>

</style>