<template>
    <v-card
        :style="editingDesc ? 'border: 2px solid #EA492F !important;' : ''"
        class="mx-6 mb-10"
        color="grey darken-3"
    >
        <v-card-subtitle class="ma-0 pb-2">
            Description
        </v-card-subtitle>
        <v-row>
            <v-textarea
                v-model="editableDescription"
                :disabled="disableEdit"
                :counter="editingDesc"
                :rules="[rules.descriptionLimit]"
                background-color="grey darken-3"
                style="font-size: 10.5pt"
                auto-grow
                solo
                flat
                class="pb-2 px-5"
                @focus="editingDesc = true"
            />
            <v-row
                style="width:100%"
                flex
                class="pt-0 pb-3 px-5"
            >
                <v-col
                    class="pl-5 pt-0"
                    cols="6"
                    align="left"
                >
                    <span class="grey--text">
                        {{ descriptionCreatedAt ? "Last Updated "
                            + descriptionCreatedAt : "" }}
                    </span>
                </v-col>
                <v-col
                    class="pt-0"
                    cols="6"
                    align="right"
                >
                    <v-btn
                        v-if="editingDesc"
                        class="mr-2 pt-0"
                        color="grey"
                        small
                        @click="refreshDescription()"
                    >
                        <span class="white--text"> Cancel </span>
                    </v-btn>
                    <v-btn
                        v-if="editingDesc"
                        :disabled="(editableDescription
                            == description)
                            || editableDescription.length > 3500"
                        class="pt-0"
                        color="primary"
                        small
                        @click="savePrintDescription()"
                    >
                        Save
                    </v-btn>
                </v-col>
            </v-row>
        </v-row>
    </v-card>
</template>

<script >

import {mapState} from 'vuex';

export default {
    components: {
    },
    props:[
        'selectedItem',
        'itemType',
        'disableEdit',
    ],
    data() {
        return {
            editingDesc: false,
            editableDescription: undefined,
            description: undefined,
            descriptionCreatedAt: undefined,
        }
    },
    computed: {
        ...mapState([
            'baseURL',
            'rules',
        ]),
    },
    async mounted() {
        await this.getPrintDescription();
    },
    methods: {
        async savePrintDescription() {
            let t = await this.$auth.getTokenSilently();
            let param = {
                item_id: this.selectedItem.id,
                text: this.editableDescription,
                item_type: this.itemType
            }

            let vm = this;
            try {
                let response = await this.$http.post(
                    this.baseURL+'/database/editDescription',
                    param, 
                    {headers: {Authorization: `Bearer ${t}`}
                    })
                console.log(
                    "Got response for /database/editDescription: ", response);
                await vm.getPrintDescription();
            }
            catch(error) {
                console.log("Error for /database/editDescription: ", error);
            }
            this.editingDesc = false;
        },
        async getPrintDescription() {
            let t = await this.$auth.getTokenSilently();
            let params = {
                headers: { Authorization: `Bearer ${t}` },
                params: { 
                    param: {
                        item_id: this.selectedItem.id,
                        item_type: this.itemType,
                    }
                }
            }
            console.log("Requesting /database/descriptionByItem");
            try {
                let response = await this.$http.get(
                    this.baseURL+'/database/descriptionByItem', params);
                if (response.data.body.length > 0) {
                    let currentDesc = response.data.body[0];
                    console.log("Response for /database/descriptionByItem: ",
                        response.data.body);
                    this.editableDescription = currentDesc.text;
                    this.descriptionCreatedAt =
                        new Date(currentDesc.created_at).toLocaleString();
                    this.description = currentDesc.text;
                }
            }
            catch(e){
                console.log("Error getting item description.", e);
            }
        },
        refreshDescription() {
            this.editableDescription = this.description;
            this.editingDesc = false;
        },
    }
}
</script>

<style scoped>
</style>