import Vue from 'vue';
import VueRouter from 'vue-router';
import { authGuard } from "./auth/authGuard";
import SignIn from './views/SignIn.vue';
import About from './views/About.vue';
import ErrorView from './views/Error.vue';
import JobsOverview from './views/JobsOverview.vue';
import MaterialConfigForm from './components/MaterialConfigForm.vue';
import MaterialConfigList from './components/MaterialConfigList.vue';
import PrintDetails from './views/PrintDetails.vue';
import PrinterDetails from './views/PrinterDetails.vue';
import PrintersOverview from './views/PrintersOverview.vue';
import RecentPrints from './components/RecentPrints.vue';
import ReleasesHome from './components/ReleasesHome.vue'
import EmailVerification from './views/EmailVerification.vue';


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'signin',
        component: SignIn,
    },
    {
        path: '/about',
        name: 'about',
        component: About, // TODO
        // component: MaterialConfigList,
    },
    {
        path: '/error',
        name: 'error',
        component: ErrorView,
    },
    {
        path: '/jobs/home', // order matters, this must come first
        component: JobsOverview,
        name: 'jobsOverview'
    },
    {
        path: '/newMaterialConfig',
        name: 'newMaterialConfig',
        component: MaterialConfigForm,
    },
    {
        path: '/materialConfigList',
        name: 'materialConfigList',
        component: MaterialConfigList,
    },
    {
        path: '/prints/home',
        name: 'printsOverview',
        component: RecentPrints,
    },
    {
        path: '/printers/home', // order matters, this must come first
        name: 'printersOverview',
        component: PrintersOverview,
    },
    {
        path: '/printers/:printerName', // order matters, this must come second
        component: PrinterDetails,
        name: 'PrinterDetails',
        props: true
    },
    {
        path: '/prints/:printId', // TODO: add printsHome // order matters, this must come second
        name: 'PrintDetails',
        component: PrintDetails,
        props: true
    },
    {
        path: '/releases/home',
        name: 'ReleasesHome',
        component: ReleasesHome,
    },
    {
        path: '/verifyEmail',
        name: 'verifyEmail',
        component: EmailVerification,
    },
];

routes.forEach(route => {
    if (route.name != 'error') {
        route.beforeEnter = authGuard;
    }
});

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router
