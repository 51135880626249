import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router.js';
import { Auth0Plugin } from "./auth";
import axios from 'axios';
import {store} from './store/store.js';

import configs from "./config.json";
const deploymentEnv = process.env.VUE_APP_DEPLOYMENT_ENV;
var authConfig = configs[deploymentEnv].auth;

Vue.prototype.$http = axios;

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
    domain: authConfig.domain,
    clientId: authConfig.clientId,
    audience: authConfig.audience,
    onRedirectCallback: appState => {
        router.push(
            appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname
        );
    }
});


Vue.config.productionTip = false


store.commit('SET_ENV_CONFIGS', deploymentEnv);

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
