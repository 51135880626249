import axios from 'axios';

const storageBucketTypes = {
    PRINTER_UPLOADS: 'printerUploads',
    CLOUD_3DFS: 'cloud3dfs',
    ATTACHMENTS: 'attachment'
}

function getStorageFile(token, baseURL, bucketType, pathHelper, absPath) {
    // if the absolute path is not given, then build it using path helper
    let path = undefined;
    if (!absPath) {
        path = [
            pathHelper.printerName,
            pathHelper.year,
        ];
        if (pathHelper.type) {
            path.push(pathHelper.type);
        }
        path.push(pathHelper.fileName);
        path = path.join("/");
    }
    else {
        path = absPath;
    }
    // console.log("looking at bucket path: ", path)

    return axios.get(baseURL+'/database/downloadFile', {
        headers: { Authorization: `Bearer ${token}` },
        params: {param: {"path": path, "bucketType": bucketType}}
    });
}

export {getStorageFile, storageBucketTypes}