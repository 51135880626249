import { getInstance } from "./index";
import {store} from '../store/store.js';

export const authGuard = (to, from, next) => {
    const authService = getInstance();

    const fn = async () => {
        // If the user is authenticated, continue with the route
        if (authService.isAuthenticated) {
            // if users email is not verified, do not let them go anywhere
            if (to.name != 'verifyEmail' && !authService.user.email_verified) {
                return next('/verifyEmail')
            }

            // if trying to go to signin or verify email when email is already verified, go to landing page
            if (to.name == 'signin' ||
                (to.name == 'verifyEmail' && authService.user.email_verified)) {
                return next('/printers/home')
            }

            try {
                // call update IAM, only updates first time and once an hour
                let token = await authService.getTokenSilently();
                await store.dispatch('UPDATE_IAM', token);
            }
            catch (e) {
                console.error("Could not update IAM: ", e);
            }
            finally {
                // do not allow user to see material list if not a fluxdev user
                if (to.name == 'materialConfigList' && !store.state.iam.fluxdev) {
                    return next('/error');
                }
                return next();
            }
        }
        else if (to.name == 'signin') {
            // if accessing sign in page with no authentication, then show the sign in page
            return next();
        }

        // all other pages will redirect to log in
        authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });

    };

    // If loading has already finished, check our auth state using `fn()`
    if (!authService.loading) {
        return fn();
    }

    // Watch for the loading property to change before we check isAuthenticated
    authService.$watch("loading", loading => {
        if (loading === false) {
            return fn();
        }
    });
};