<template>
    <v-container>
        <v-layout wrap>
            <v-flex
                v-for="(i, j) in cardsToRender"
                :key="i"
                xs12
                sm6
                md4
                lg3
                class="d-flex flex-column"
            >
                <v-skeleton-loader
                    v-if="loading"
                    type="article"
                    class="ma-2"
                    max-width="300"
                    height="225"
                />
                <PrinterStatCard
                    v-else
                    :printer-info="printerData[j]"
                    class="ma-1"
                />
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import {mapState} from 'vuex';
import PrinterStatCard from '../components/PrinterStatCard';

export default {
    components: {
        PrinterStatCard,
    },
    props:[
        'width',
        'height',
        'input'
    ],
    data: function(){
        return {
            loading: true,
            skeletonLoadCount: 12,
            cardsToRender: undefined,
            printerData: [],
        }
    },
    computed: {
        ...mapState([
            'baseURL',
            'userConfigs'
        ])
    },
    watch: {
        input: function() {
            this.updatePrintStats();
        }
    },
    mounted() {
        // set the number of skeleton loader cards
        if (this.input.length == 0) {
            this.cardsToRender = this.skeletonLoadCount;
        }
    },
    beforeDestroy () {
        clearInterval(this.autoUpdate);
    },
    methods: {
        updatePrintStats() {

            let vm = this;
            vm.printerData = [];
            if(this.input) {
                this.input.forEach(element => {
                    if (element.printer_retired) { return; }

                    let date = element.l_created_at ? new Date(element.l_created_at) : new Date(element.p_created_at);
                    element.layerTime = date.toLocaleDateString() + " " + date.toLocaleTimeString();
                    element.rawDate = date;
                    vm.printerData.push(element);
                });

                // sort the printers first by type then by name
                vm.printerData.sort(function(a, b) {
                    let nameA = a.printer_type.toUpperCase();
                    let nameB = b.printer_type.toUpperCase();
                    return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0 ||
                        a.printer_name.toUpperCase().localeCompare(b.printer_name.toUpperCase());
                });

                this.cardsToRender = vm.printerData.length;
                this.loading = false; // flag to turn off skeleton load

                // vm.$http.get(this.baseURL+"/printsByPrinter", {params})
                // .then((response) => {
                //     console.log("Got response from server for /printsByPrinter: ", response.data.body);
                //     if(response.data.body) {
                //         response.data.body.forEach(elem => {
                //             let index = vm.printerData.findIndex((obj => obj.name == elem.name));
                //             vm.printerData[index].lifetimeCount = elem.count;
                //         })
                //     }
                // });

                console.log("printerData: ", vm.printerData);
            }
        },
    }

}
</script>