<template>
    <v-container fluid>
        <PrintList
            title="Last 100 Prints"
            :raw-prints="rawPrints"
            :loading="loadingBarOn"
        />
    </v-container>
</template>

<script>
import {mapState} from 'vuex';
import PrintList from './PrintList.vue'

export default {
    components: {
        PrintList,
    },
    data: function(){
        return {
            prints: [],
            rawPrints: [],
            loadingBarOn: true,
        }
    },
    computed: {
        ...mapState([
            'baseURL',
        ])
    },
    mounted() {
        this.getRecentPrints();
    },
    methods: {
        async getRecentPrints() {
            let vm = this;
            let t = await this.$auth.getTokenSilently();

            this.$http.get(this.baseURL+'/database/prints', {
                headers: { Authorization: `Bearer ${t}` }
            })
                .then((response) => {
                    console.log("Response for /database/prints: " + response.data.body);
                    vm.rawPrints = response.data.body.map((print) => {return print});
                    this.loadingBarOn= false;
                })
        }
    }
};
</script>
