<template>
    <v-card
        class="my-3"
        color="gray3"
    >
        <v-card-title>
            <span class="overline">{{ title }}</span>
            <v-spacer />
            <v-text-field
                v-model="searchString"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            />
            <v-spacer />

            <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        :disabled="rawPrints.length==0"
                        v-bind="attrs" 
                        color="primary"
                        fab
                        outlined
                        small
                        @click="saveToCSV()"
                        v-on="on"
                    >
                        <v-icon>mdi-file-download</v-icon>
                    </v-btn>
                </template>
                <span>Download To CSV</span>
            </v-tooltip>
        </v-card-title>

        <v-data-table
            :headers="headers"
            :items="printsForDisplay"
            :search="searchString"
            :sort-by="['id']"
            :sort-desc="[true]"
            :loading="loading"
            :items-per-page="entriesPerPage"
            multi-sort
            class="elevation-1 my-3 dark-mode"
            dark
        >
            <template v-slot:[`item.successful`]="{ item }">
                <v-chip
                    :color="status[item.successful].color"
                    dark
                >
                    <v-icon>{{ status[item.successful].icon }}</v-icon>
                </v-chip>
            </template>
            <template v-slot:[`item.action`]="{ item }">
                <v-icon
                    small
                    color="primary"
                    class="mr-2"
                    @click="clickPrint(item)"
                >
                    mdi-open-in-new
                </v-icon>
            </template>
        </v-data-table>
    </v-card>
</template>


<script>
import {mapState} from 'vuex';
import {exportToCSV} from '../assets/js/exportToCSV.js';

export default {
    props: [
        'rawPrints',
        'title',
        'loading',
        'entriesPerPage'
    ],
    data: function() {
        return {
            headers: [
                { text: 'ID', align: 'left', value: 'id'},
                { text: 'Printed At', value: 'printed_at', sortable: false },
                //{ text: 'Duration', value: 'duration' },
                { text: 'Successful', value: 'successful' },
                { text: 'Build Name', value: 'build_name' },
                { text: 'Total Layers', value: 'total_layers' },
                { text: 'Printer', value: 'printer' },
                { text: 'Config', value: 'matConfigNameDisplay' },
                { text: 'FH version', value: 'printer_host_version' },
                { text: 'Details', value: 'action', sortable: false }
            ],
            searchString: "",
            selectedPrint: undefined,
        }
    },
    computed: {
        ...mapState([
            'baseURL',
            'dialogs',
            'status'
        ]),
        printsForDisplay: function() {
            let printsForDisplay = [];
            if (this.rawPrints) {
                this.rawPrints.forEach((print) => {
                    let modified = print;
                    modified.successful = modified.successful ? 'success' : (modified.successful == false ? 'failed' : 'unknown')

                    modified['sized_fiber'] = 
                        (modified.sizing_fiber_batch == undefined) ? modified.master_sizing_fiber_batch : modified.sizing_fiber_batch;

                    modified.build_name_full = modified.build_name;
                    if (modified.build_name && modified.build_name.length > 20) {
                        modified.build_name = modified.build_name.slice(0, 20) + "...";
                    }

                    modified.printed_at = new Date(modified.printed_at).toLocaleString();
                    modified.engineer = modified.engineer == undefined ? 'N/A' : modified.engineer;
                    modified.customer = modified.customer ? modified.customer : 'N/A';
                    modified.matConfigNameDisplay = modified.material_config || modified.material_config_3x || modified.local_mat_config_name;

                    let lastLayer = modified.last_layer ? modified.last_layer : 0
                    modified.layersComplete = lastLayer == 0 ? "N/A" : lastLayer + "/" + modified.total_layers;
                    modified.percentComplete = Math.round(lastLayer/modified.total_layers*100);
                    modified.est_duration = this.msToHM(modified.est_duration);
                    modified.duration = (modified.end_time == undefined ? 'N/A' :
                        (this.msToHM(new Date(modified.end_time) - new Date(modified.printed_at))));

                    printsForDisplay.push(modified);
                });
            }
            return printsForDisplay;
        }
    },
    methods: {
        clickPrint(selected) {
            // TODO: (see TODO in PrintDetails.vue) opening the print details in a new tab allows users to go back to the
            // print list without waiting a long time for the query response to return from the DB.
            // however, opening 'PrintDetails' in a new tab will not carry over the props.
            // the 'PrintDetails' page has its own way of searching for the print, that means there
            // is dead code. 
            // possible fix: use vuex keep-alive(?) or save the print list in vuex(?)
            let routeData = this.$router.resolve({
                name: 'PrintDetails',
                params: {
                    printId: selected.id,
                    selectedPrintProp: selected
                }
            });
            window.open(routeData.href, '_blank');
        },
        saveToCSV() {
            exportToCSV(this.rawPrints, "db_query")
        },
        msToHM(duration) {
            let minutes = Math.floor((duration / (1000 * 60)) % 60),
                hours = Math.floor((duration / (1000 * 60 * 60)));

            return hours + "h " + minutes + "m";
        }
    }
}

</script>