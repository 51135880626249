<template>
    <v-container>
        <div class=" pa-0 d-flex flex-row justify-center">
            <v-img
                :key="mainImageURL"
                :src="mainImageURL"
                :max-width="width"
                class="align-self-start"
            >
                <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    />
                </template>
            </v-img>
        </div>
    </v-container>
</template>

<script>
import {mapState} from 'vuex';
import {getStorageFile, storageBucketTypes} from '../assets/js/getStorageFile';

export default {
    props:[
        'width',
        'printerName'
    ],
    data: function(){
        return {
            // lazyLoadImg: require('../../logo/lazy_load_fluxhost.png'),
            mainImageURL: undefined,
            projImageURL: undefined,
        }
    },
    computed: {
        ...mapState([
            'baseURL',
        ])
    },
    mounted() {
        this.updateMainScreen();
        this.autoUpdateMainScreen = setInterval(this.updateMainScreen, 60000);
    },
    beforeDestroy () {
        clearInterval(this.autoUpdateMainScreen);
    },
    methods: {
        updateMainScreen() {
            this.getScreenFromStorage({
                printerName: this.printerName,
                year: new Date().getFullYear(),
                type: 'Screen',
                fileName: '1.png'
            })
                .then((response) => {
                    console.log("Retrieved screenshot for main screen");
                    this.mainImageURL = response.data.body;
                })
        },
        // updateProjScreen() {
        //     this.getScreenFromStorage({
        //         printerName: this.printerName,
        //         year: new Date().getFullYear(),
        //         type: 'Screen',
        //         fileName: '0.png'
        //     })
        //     .then((response) => {
        //         console.log("Retrieved screenshot for proj screen");
        //         this.projImageURL = response.data.body;
        //     })
        // },
        async getScreenFromStorage(pathDetails) {
            let t = await this.$auth.getTokenSilently();
            return getStorageFile(t, this.baseURL, storageBucketTypes.PRINTER_UPLOADS, pathDetails)
        },
    }

}
</script>

<style lang="scss">
.scale-enter-active {
  transition: opacity .3s ease-in-out;
}

.scale-leave-active {
  transition: none;
}

.scale-enter, .scale-leave, .scale-leave-to {
  opacity: 0;
}

.scale-enter-to {
  opacity: 1;
}
</style>