<template>
    <PrinterStats
        :width="clientWidth"
        :input="layersByPrinterResult"
    />
</template>

<script>
import {mapState} from 'vuex';
import PrinterStats from '../components/PrinterStats.vue';

export default {
    components: {
        PrinterStats,
    },
    data: function() {
        return {
            clientWidth: document.documentElement.clientWidth,
            layersByPrinterResult: [],
        }
    },
    computed: {
        ...mapState([
            'baseURL',
        ])
    },
    mounted() {
        this.autoUpdate = setInterval(this.updateLayersByPrinter, 20000);
        this.updateLayersByPrinter();
    },
    beforeDestroy () {
        clearInterval(this.autoUpdate);
    },
    methods: {
        async updateLayersByPrinter() {
            let t = await this.$auth.getTokenSilently();

            this.$http.get(this.baseURL+"/database/recentPrints", {
                headers: { Authorization: `Bearer ${t}` }
            })
                .then((response) => {
                    console.log("Got response for /database/recentPrints ", response);
                    if (response.data.body) {
                        this.layersByPrinterResult = response.data.body;
                    }
                })
        }
    }
}
</script>

<style lang="scss">

</style>